import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
// eslint-disable-next-line
import styles from "./styles/source_sans_pro.css";
import VuePapaParse from "vue-papa-parse";

Vue.config.productionTip = false;
Vue.use(VuePapaParse);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
